import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import GalleryLayout from "./galleryLayout"

function Flowers() {
  return (
    <GalleryLayout title="Flowers">
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596090130/flowers/flower-1_xsofa4.jpg"
        alt="Flowers"
        className="gallery-picture-v"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596090133/flowers/flower-2_dodjpw.jpg"
        alt="Flowers"
        className="gallery-picture-v"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596090137/flowers/flower-3_ujnxkk.jpg"
        alt="Flowers"
        className="gallery-picture"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596090141/flowers/flower-4_jyhipg.jpg"
        alt="Flowers"
        className="gallery-picture"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596090145/flowers/flower-5_tzgmfo.jpg"
        alt="Flowers"
        className="gallery-picture"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596090149/flowers/flower-6_czavsw.jpg"
        alt="Flowers"
        className="gallery-picture"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596090153/flowers/flower-7_kexcor.jpg"
        alt="Flowers"
        className="gallery-picture-v"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596090157/flowers/flower-8_n9ss5q.jpg"
        alt="Flowers"
        className="gallery-picture-v"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596090161/flowers/flower-9_wayhj8.jpg"
        alt="Flowers"
        className="gallery-picture-v"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596090165/flowers/flower-10_hib0b2.jpg"
        alt="Flowers"
        className="gallery-picture-v"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596090169/flowers/flower-11_ozr4j8.jpg"
        alt="Flowers"
        className="gallery-picture-v"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596090174/flowers/flower-12_n7jiga.jpg"
        alt="Flowers"
        className="gallery-picture-v"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596090177/flowers/flower-13_m1xtwc.jpg"
        alt="Flowers"
        className="gallery-picture-v"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596090181/flowers/flower-14_uokuv7.jpg"
        alt="Flowers"
        className="gallery-picture-v"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596090186/flowers/flower-15_gulwti.jpg"
        alt="Flowers"
        className="gallery-picture-v"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596090190/flowers/flower-16_yg0u0p.jpg"
        alt="Flowers"
        className="gallery-picture"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596090194/flowers/flower-17_toj7rz.jpg"
        alt="Flowers"
        className="gallery-picture"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596090198/flowers/flower-18_owjirn.jpg"
        alt="Flowers"
        className="gallery-picture"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596090202/flowers/flower-19_be1o4x.jpg"
        alt="Flowers"
        className="gallery-picture-v"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596090206/flowers/flower-20_i3pkgl.jpg"
        alt="Flowers"
        className="gallery-picture-v"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596090210/flowers/flower-21_da1ucq.jpg"
        alt="Flowers"
        className="gallery-picture-v"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596090214/flowers/flower-22_sw9div.jpg"
        alt="Flowers"
        className="gallery-picture-v"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596090218/flowers/flower-23_ysrms3.jpg"
        alt="Flowers"
        className="gallery-picture"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596090222/flowers/flower-24_nvn1kq.jpg"
        alt="Flowers"
        className="gallery-picture-v"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596090226/flowers/flower-25_mbdlrx.jpg"
        alt="Flowers"
        className="gallery-picture-v"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596090230/flowers/flower-26_sdwvrf.jpg"
        alt="Flowers"
        className="gallery-picture-v"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596090234/flowers/flower-27_igoi6i.jpg"
        alt="Flowers"
        className="gallery-picture-v"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596090238/flowers/flower-28_sdnjil.jpg"
        alt="Flowers"
        className="gallery-picture-v"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596090242/flowers/flower-29_rsrnru.jpg"
        alt="Flowers"
        className="gallery-picture-v"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596090246/flowers/flower-30_hvixg0.jpg"
        alt="Flowers"
        className="gallery-picture-v"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596090250/flowers/flower-31_vn8fhq.jpg"
        alt="Flowers"
        className="gallery-picture-v"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596090254/flowers/flower-32_yk9qij.jpg"
        alt="Flowers"
        className="gallery-picture-v"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596090258/flowers/flower-33_sbmbyk.jpg"
        alt="Flowers"
        className="gallery-picture-v"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596090262/flowers/flower-34_wrveol.jpg"
        alt="Flowers"
        className="gallery-picture-v"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596090266/flowers/flower-35_ynug8g.jpg"
        alt="Flowers"
        className="gallery-picture-v"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596090270/flowers/flower-36_acrp1a.jpg"
        alt="Flowers"
        className="gallery-picture-v"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596090435/flowers/flower-77_ysqupy.jpg"
        alt="Flowers"
        className="gallery-picture-v"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596090439/flowers/flower-78_edxkrp.jpg"
        alt="Flowers"
        className="gallery-picture-v"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596090443/flowers/flower-79_cwvemq.jpg"
        alt="Flowers"
        className="gallery-picture-v"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596090447/flowers/flower-80_ddc9yt.jpg"
        alt="Flowers"
        className="gallery-picture-v"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596090451/flowers/flower-81_xm61xl.jpg"
        alt="Flowers"
        className="gallery-picture-v"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596090455/flowers/flower-82_xyjc4n.jpg"
        alt="Flowers"
        className="gallery-picture-v"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596090459/flowers/flower-83_ttmxcl.jpg"
        alt="Flowers"
        className="gallery-picture-v"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596090463/flowers/flower-84_vdy3az.jpg"
        alt="Flowers"
        className="gallery-picture-v"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596090467/flowers/flower-85_yuathe.jpg"
        alt="Flowers"
        className="gallery-picture-v"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596090471/flowers/flower-86_ncgixc.jpg"
        alt="Flowers"
        className="gallery-picture-v"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596090475/flowers/flower-87_joeldf.jpg"
        alt="Flowers"
        className="gallery-picture-v"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596090479/flowers/flower-88_kqepce.jpg"
        alt="Flowers"
        className="gallery-picture-v"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596090483/flowers/flower-89_ypl8qi.jpg"
        alt="Flowers"
        className="gallery-picture"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596090487/flowers/flower-90_qbwe6d.jpg"
        alt="Flowers"
        className="gallery-picture-v"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596090491/flowers/flower-91_rvy0nj.jpg"
        alt="Flowers"
        className="gallery-picture-v"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596090495/flowers/flower-92_drpdni.jpg"
        alt="Flowers"
        className="gallery-picture-v"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596090499/flowers/flower-93_tk8p0j.jpg"
        alt="Flowers"
        className="gallery-picture-v"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596090503/flowers/flower-94_xil36o.jpg"
        alt="Flowers"
        className="gallery-picture-v"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596090507/flowers/flower-95_vxd87w.jpg"
        alt="Flowers"
        className="gallery-picture-v"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596090511/flowers/flower-96_mrme1w.jpg"
        alt="Flowers"
        className="gallery-picture-v"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596090515/flowers/flower-97_znjpc7.jpg"
        alt="Flowers"
        className="gallery-picture-v"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596090519/flowers/flower-98_h6ze10.jpg"
        alt="Flowers"
        className="gallery-picture"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596090523/flowers/flower-99_dmplp6.jpg"
        alt="Flowers"
        className="gallery-picture-v"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596090527/flowers/flower-100_fny9ea.jpg"
        alt="Flowers"
        className="gallery-picture-v"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596090531/flowers/flower-101_wyenjw.jpg"
        alt="Flowers"
        className="gallery-picture-v"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596090535/flowers/flower-102_kekcim.jpg"
        alt="Flowers"
        className="gallery-picture-v"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596090274/flowers/flower-37_tw4acu.jpg"
        alt="Flowers"
        className="gallery-picture"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596090278/flowers/flower-38_oemdub.jpg"
        alt="Flowers"
        className="gallery-picture"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596090274/flowers/flower-37_tw4acu.jpg"
        alt="Flowers"
        className="gallery-picture"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596090286/flowers/flower-40_dwcbb1.jpg"
        alt="Flowers"
        className="gallery-picture"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596090290/flowers/flower-41_byaozf.jpg"
        alt="Flowers"
        className="gallery-picture"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596090294/flowers/flower-42_pdflhv.jpg"
        alt="Flowers"
        className="gallery-picture"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596090298/flowers/flower-43_lwkfns.png"
        alt="Flowers"
        className="gallery-picture"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596090302/flowers/flower-44_bvfcca.jpg"
        alt="Flowers"
        className="gallery-picture"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596090306/flowers/flower-45_cgasky.jpg"
        alt="Flowers"
        className="gallery-picture"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596090310/flowers/flower-46_fouedn.jpg"
        alt="Flowers"
        className="gallery-picture"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596090314/flowers/flower-47_k1nqpo.jpg"
        alt="Flowers"
        className="gallery-picture"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596090322/flowers/flower-49_rjxeqh.jpg"
        alt="Flowers"
        className="gallery-picture"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596090326/flowers/flower-50_yzhaae.jpg"
        alt="Flowers"
        className="gallery-picture"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596090331/flowers/flower-51_ldepih.jpg"
        alt="Flowers"
        className="gallery-picture-v"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596090335/flowers/flower-52_t4sehh.jpg"
        alt="Flowers"
        className="gallery-picture-v"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596090338/flowers/flower-53_suyt3j.jpg"
        alt="Flowers"
        className="gallery-picture"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596090342/flowers/flower-54_kfmylm.jpg"
        alt="Flowers"
        className="gallery-picture"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596090346/flowers/flower-55_ydn7o9.jpg"
        alt="Flowers"
        className="gallery-picture-v"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596090350/flowers/flower-56_h0bx82.jpg"
        alt="Flowers"
        className="gallery-picture-v"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596090354/flowers/flower-57_okboox.jpg"
        alt="Flowers"
        className="gallery-picture-v"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596090358/flowers/flower-58_runiyb.jpg"
        alt="Flowers"
        className="gallery-picture-v"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596090362/flowers/flower-59_z3dzxk.jpg"
        alt="Flowers"
        className="gallery-picture-v"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596090366/flowers/flower-60_auujrg.jpg"
        alt="Flowers"
        className="gallery-picture-v"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596090370/flowers/flower-61_vsv0pm.jpg"
        alt="Flowers"
        className="gallery-picture-v"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596090378/flowers/flower-63_hsfgfl.jpg"
        alt="Flowers"
        className="gallery-picture-v"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596090378/flowers/flower-63_hsfgfl.jpg"
        alt="Flowers"
        className="gallery-picture-v"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596090382/flowers/flower-64_q0nsyj.jpg"
        alt="Flowers"
        className="gallery-picture-v"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596090386/flowers/flower-65_edq5qm.jpg"
        alt="Flowers"
        className="gallery-picture-v"
      />

      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596090394/flowers/flower-67_fjejxf.jpg"
        alt="Flowers"
        className="gallery-picture-v"
      />

      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596090402/flowers/flower-69_orzp8l.jpg"
        alt="Flowers"
        className="gallery-picture-v"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596090406/flowers/flower-70_qkrnvw.jpg"
        alt="Flowers"
        className="gallery-picture-v"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596090410/flowers/flower-71_q9kyfg.jpg"
        alt="Flowers"
        className="gallery-picture-v"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596090414/flowers/flower-72_hqyb2l.jpg"
        alt="Flowers"
        className="gallery-picture"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596090418/flowers/flower-73_l0qamx.jpg"
        alt="Flowers"
        className="gallery-picture"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596090422/flowers/flower-74_ld4m58.jpg"
        alt="Flowers"
        className="gallery-picture"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596090427/flowers/flower-75_wakdvn.jpg"
        alt="Flowers"
        className="gallery-picture"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596090539/flowers/flower-103_mhxyrp.jpg"
        alt="Flowers"
        className="gallery-picture"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596090544/flowers/flower-104_oouh9a.jpg"
        alt="Flowers"
        className="gallery-picture"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596090430/flowers/flower-76_j7s4q7.jpg"
        alt="Flowers"
        className="gallery-picture"
      />
    </GalleryLayout>
  )
}

export default Flowers
